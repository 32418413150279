import React, { useState } from "react";
import { Link } from "react-router-dom";

function RegistrationForm() {
  const [formValues, setFormValues] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    usernameError: false,
    emailError: false,
    passwordError: false,
    confirmPasswordError: false,
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
    setFormErrors({ ...formErrors, [`${id}Error`]: false });
  };

  const validateForm = () => {
    let isValid = true;
    const { username, email, password, confirmPassword } = formValues;
    const newErrors = {
      usernameError: false,
      emailError: false,
      passwordError: false,
      confirmPasswordError: false,
    };

    // Validate username
    if (!username) {
      newErrors.usernameError = true;
      isValid = false;
    }

    // Validate email
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || !emailPattern.test(email)) {
      newErrors.emailError = true;
      isValid = false;
    }

    // Validate password
    if (!password) {
      newErrors.passwordError = true;
      isValid = false;
    }

    // Validate confirm password
    if (password !== confirmPassword) {
      newErrors.confirmPasswordError = true;
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      alert("Form submitted successfully!");
      // Submit the form or handle data here
    }
  };

  return (
    <div className=" flex items-center justify-center min-h-screen">
      <div className="bg-gray-200 rounded-lg shadow-lg p-8 max-w-md w-full shadow-xl">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Create an Account
        </h2>
        <form onSubmit={handleSubmit} noValidate>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-700 font-semibold mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your username"
              value={formValues.username}
              onChange={handleInputChange}
              required
            />
            {formErrors.usernameError && (
              <p className="text-red-500 text-sm mt-2">Username is required.</p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-semibold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your email"
              value={formValues.email}
              onChange={handleInputChange}
              required
            />
            {formErrors.emailError && (
              <p className="text-red-500 text-sm mt-2">
                Please enter a valid email.
              </p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 font-semibold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your password"
              value={formValues.password}
              onChange={handleInputChange}
              required
            />
            {formErrors.passwordError && (
              <p className="text-red-500 text-sm mt-2">Password is required.</p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 font-semibold mb-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Confirm your password"
              value={formValues.confirmPassword}
              onChange={handleInputChange}
              required
            />
            {formErrors.confirmPasswordError && (
              <p className="text-red-500 text-sm mt-2">
                Passwords do not match.
              </p>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
          >
            Register
          </button>
        </form>
        <p className="text-center text-gray-600 mt-4">
          Already have an account?{" "}
          <Link to={"/sign-in"}>
            <p className="text-blue-500 font-semibold inline">Sign In</p>
          </Link>
        </p>
      </div>
    </div>
  );
}

export default RegistrationForm;
