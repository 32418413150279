import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import SideImage from "../../../Assets/landingPage1.png";

const HeroSection = () => {
  const text1 = "Convenient and Secure File";
  const text2 = "Sharing with Xopy";
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const typingAnimation = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.08,
      },
    },
  };

  const letterAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="flex flex-col mb-20 mt-3 text-center w-full md:grid md:grid-cols-2 md:gap-0 justify-center items-center px-8 my-auto h-[80vh] ">
      {/* Image Section - Now first on mobile */}
      <div className="col-span-1 mb-8 md:mb-0 order-first md:order-last md:flex md:justify-center">
        <img
          src={SideImage}
          alt="Xopy"
          className="w-[20rem] md:w-[33rem] rounded-3xl md:shadow-[25px_20px_7px_7px_#f6e05e] shadow-[13px_12px_10px_7px_#f6e05e]"
        />
      </div>

      {/* Text Content Section */}
      <div className="col-span-1 md:flex md:flex-col md:justify-center items-center">
        <h1 className="text-[1.85rem] md:px-2 md:text-[2.5rem] font-bold leading-tight text-center mt-4">
          {showText ? (
            <motion.div
              className="flex flex-col justify-center md:px-6"
              variants={typingAnimation}
              initial="hidden"
              animate="visible"
            >
              <div className="px-1.5 md:px-0">
                {text1.split("").map((char, index) => (
                  <motion.span
                    key={`line1-${index}`}
                    variants={letterAnimation}
                  >
                    {char === " " ? "\u00A0" : char}
                  </motion.span>
                ))}
              </div>
              <div>
                {text2.split("").map((char, index) => (
                  <motion.span
                    key={`line2-${index}`}
                    variants={letterAnimation}
                  >
                    {char === " " ? "\u00A0" : char}
                  </motion.span>
                ))}
              </div>
            </motion.div>
          ) : (
            <></>
          )}
        </h1>
        <div className="text-sm md:text-base mt-2 text-pretty px-6">
          Xopy makes it easy to share files of any size with your team, clients,
          or anyone else. Our platform is designed with security and convenience
          in mind.
        </div>
        <div className="mt-4 flex flex-col items-center md:flex-row md:justify-start md:px-6">
          <button className="bg-black hover:bg-gray-800 text-white font-bold py-2 px-8 rounded mb-4 md:mb-0 md:mr-4 w-full md:w-auto">
            Get Started
          </button>
          <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-8 border border-gray-400 rounded shadow w-full md:w-auto">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
