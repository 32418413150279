import React from "react";

const Footer = () => {
  return (
    <div className="bottom-0 left-0 right-0  mt-10 p-4 bg-white shadow-md z-10">
      <p className="text-center text-sm">
        © 2024 Xopy. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
